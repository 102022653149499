app-rent-house {
  .list {
    @apply flex gap-1 items-start;

    > p {
      @apply text-secondary flex-none mt-1;
    }

    > div {
      @apply flex flex-wrap gap-1;

      > span {
        @apply px-2 py-1 border border-gray-300 rounded cursor-pointer whitespace-nowrap text-sm inline-block;
      }
    }
  }
}
